






































































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import JobSearchResult from '@/components/search-results/JobSearchResult.vue'
import { Action } from 'vuex-class'
import JobFamily from '@/components/hszg/JobFamily.vue'
import { SimpleServerResponse } from '@/store/types'
import { GET_PROFILE_KODE_STATUS } from '@/store/user/actions.type'
import TargetCompetenceProfileResult from '@/components/kode/TargetCompetenceProfileResult.vue'
import { JobFinderType, KodeStatusDTO } from '@/store/kode/types'
import InfoBox from '@/components/hszg/InfoBox.vue'
import Competence from '@/components/hszg/Competence.vue'
import MatchingResultOverview from '@/components/search-results/MatchingResultOverview.vue'
import { GET_JOB_ADS_TO_JOB_FAMILY, GET_PROFILE_STUDENT_SETTINGS } from '@/store/student/actions'
import { StudentProfileSettings } from '@/store/student/types'

  @Component({
    components: { MatchingResultOverview, Competence, InfoBox, TargetCompetenceProfileResult, JobFamily, JobSearchResult }
  })
export default class JobsToSelectedJobFamily extends Vue {
    @Action(GET_PROFILE_KODE_STATUS)
    public getProfileKodeStatus: () => Promise<KodeStatusDTO>

    @Action(GET_JOB_ADS_TO_JOB_FAMILY)
    public getJobsToJobFamily: () => Promise<Array<JobFinderType>>

    @Action(GET_PROFILE_STUDENT_SETTINGS)
    public getProfileStudentSettings: () => Promise<SimpleServerResponse>

    private jobFinderResult: Array<JobFinderType> = []

    kodeStatusLoading = true
    profileSettingsLoading = true
    jobsLoading = true
    kodeStatus = ''
    jobFamilyStatus = true
    jobFamily: string | null = null

    studentProfileSettings: StudentProfileSettings = {
      dontMatchCompetences: false
    }

    get loading (): boolean {
      return this.jobsLoading && this.profileSettingsLoading && this.kodeStatusLoading
    }

    get type (): string {
      if (this.studentProfileSettings.dontMatchCompetences) {
        return 'JOB_AD_NO_COMP'
      } else {
        return 'JOB_AD'
      }
    }

    mounted (): void {
      this.getProfileKodeStatus().then(data => {
        this.kodeStatus = data.kodeStatus
        if (this.kodeStatus === 'FINISH') {
          this.loadData()
        } else {
          this.jobsLoading = false
        }
      }).catch(() => {
        this.jobsLoading = false
      }).finally(() => {
        this.kodeStatusLoading = false
      })

      this.getProfileStudentSettings().then((data) => {
        this.studentProfileSettings = data.content
      }).catch((error) => {
        this.$root.$emit('alert', this.$i18n.t('warning.error!'), this.$i18n.t('settings.' + error.message), true)
      }).finally(() => {
        this.profileSettingsLoading = false
      })
    }

    private loadData (): void {
      this.jobsLoading = true
      this.getJobsToJobFamily().then(data => {
        this.jobFamily = data.length > 0 ? data[0].competenceProfileType : null
        this.jobFinderResult = data
      }).catch(error => {
        if (error.message === 'no job family selected') {
          this.jobFamilyStatus = false
        } else {
          this.$root.$emit('alert',
            this.$t('warning.error!').toString(),
            this.$tc('warning.could-not-load', 2, { what: this.$tc('job-ad.job-ad', 2) }).toString() + ' ' + this.$t('warning.try-again'),
            true)
        }
      }).finally(() => {
        this.jobsLoading = false
      })
    }
}
